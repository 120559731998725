<template>
    <markets-ui-container
        :class="cls"
        :skin="watchedSettings.skin"
        :errorMessage="errorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="watchedSettings.showSetting"
        @setting-click="toggleSettings"
    >
        <div
            ref="content"
            slot="content"
            :class="[namespace('container')]"
            v-if="!errorMessage"
        >
            <mwc-research-list-component
                ref="researchComponent"
                v-if="researchSetting"
                :id="researchComponentId"
                :mwc-id="researchComponentId"
            ></mwc-research-list-component>
        </div>
        <markets-ui-settings
            slot="settings"
            ref="qualitativeResearchUiSettings"
            :skin="watchedSettings.skin"
            :showBack="!!settingsPopoverNavSelected"
            :triggered-by="settingsPopoverTriggeredId"
            :visible="settingsPopoverVisible"
            @hide="hideSettingsPopover"
            @back="changeSettingsNav"
        />
    </markets-ui-container>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';

const RESEARCH_COMPONENTS = [
    'advisorWorkstation',
    'assetFlows',
    'analystNotes',
    'latestAnalyst'
];
const RESEARCH_ENVIRONMENTS = ['stg', 'uat', 'prod'];
const { mixins, utils } = mwcMarketsCore;
export default {
    name: 'mwc-markets-qualitative-research-ui',
    mixins: [mixins.componentUI],
    props: {
        researchSetting: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            settingsPopoverVisible: false,
            settingsPopoverTriggeredId: null,
            settingsPopoverNavSelected: undefined,
            settingsPopoverTitle: undefined,
            researchComponentId: `qualitative-research-${utils.guid()}`
        };
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('qualitative-research');
    },
    watch: {
        researchComponentSettings: {
            handler(value) {
                this.setMwcConfigurations(value);
                this.$nextTick(() => {
                    if (this.$refs.researchComponent) {
                        this.$refs.researchComponent.initialConfig =
                            value.initConfig;
                    }
                });
            },
            deep: true
        }
    },
    computed: {
        cls() {
            const cls = [this.namespace()];
            if (this.watchedSettings.skin) {
                cls.push(
                    this.namespace(
                        `-${utils.getSkinSuffix(this.watchedSettings.skin)}`
                    )
                );
            }
            return cls;
        },
        /**
         *  Set popover nav menu.
         * @returns {{name: string, id: focus, title: string }[]}
         */
        settingsPopoverNav() {
            return [];
        },
        /**
         *  Set popover nav list.
         * @returns {{name: string, id: string, selected: boolean}[]}
         */
        settingsPopoverNavSelectedList() {
            return [];
        },
        researchComponentName() {
            return (
                utils.find(
                    RESEARCH_COMPONENTS,
                    item =>
                        item ===
                        (this.researchSetting &&
                            this.researchSetting.componentName)
                ) || RESEARCH_COMPONENTS[0]
            );
        },
        researchEnvironment() {
            return (
                utils.find(
                    RESEARCH_ENVIRONMENTS,
                    item =>
                        item ===
                        (this.researchSetting &&
                            this.researchSetting.environment)
                ) || RESEARCH_ENVIRONMENTS[0]
            );
        },
        researchAuthToken() {
            return this.researchSetting && this.researchSetting.authToken;
        },
        reseachAuthUID() {
            return this.researchSetting && this.researchSetting.uid;
        },
        researchComponentSettings() {
            return {
                settings: {
                    componentName: this.researchComponentName,
                    environment: this.researchEnvironment,
                    authType: 'apigee',
                    authToken: this.researchAuthToken,
                    uid: this.reseachAuthUID
                },
                initConfig: this.watchedSettings.initConfig
            };
        }
    },
    methods: {
        setMwcConfigurations(componentSettings) {
            const appConfig = {};
            appConfig.components = {};
            appConfig.components[this.researchComponentId] = {
                settings: componentSettings.settings
            };
            window.mwc.configuration.setAppConfig(appConfig);
        },
        /**
         * Change popover visible state.
         * This will invoke in horizon.
         * @param target
         */
        toggleSettings(e) {
            this.settingsPopoverVisible = !this.settingsPopoverVisible;
            this.settingsPopoverTriggeredId = utils.computeTriggerId(e.target);
        },
        /**
         * Hide popover setting.
         */
        hideSettingsPopover() {
            this.settingsPopoverVisible = false;
            this.settingsPopoverNavSelected = null;
            this.$emit('settings-popover-hide');
        },
        /**
         * Change current selected nva item.
         * @param item - item id.
         */
        changeSettingsNav(item) {
            this.settingsPopoverNavSelected = item;
            this.$refs.qualitativeResearchUiSettings.update();
        },
        /**
         * Change current nav to set the focus type.
         * @param item - focus type.
         */
        changeSettingsNavSelected(item) {
            this.$refs.qualitativeResearchUiSettings.update();
            this.settingsPopoverNavSelected = undefined;
            this.hideSettingsPopover();
        }
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-qualitative-research';

.#{$namespace} {
    &-container {
        height: 100%;
        overflow: auto;
    }

    &--dark-gray {
        .rpc-rlc-drw .rpc-qrc-dr {
            background: $mds-background-color-dark-gray;
        }

        .rpc-qrc-dr-body,
        .rpc-qrc-dr-main .rpc-qrc-dr-body .rpc-qrc-dr-body-content,
        .rpc-qrc-dr-html-header,
        .rpc-rlc-unauthorized {
            color: $mds-text-color-primary-on-dark;
        }
        .rpc-qrc-dr-video-title-and-deck {
            color: $mds-text-color-primary-on-dark;
        }
        .rpc-qrc-dr-main-header button:hover svg {
            fill: $mds-text-color-primary-on-dark;
        }
        .rpc-rlc-format-title svg,
        .rpc-rlc button svg {
            fill: $mds-text-color-secondary-on-dark;
        }
        .rpc-rlc-grid {
            td span,
            td a {
                color: $mds-text-color-primary-on-dark;
            }
            td a :hover {
                color: $mds-text-color-interactive-primary-on-dark;
            }
            th div {
                margin-top: $mds-space-half-x;
                color: $mds-text-color-secondary-on-dark;
            }
        }
    }
}
</style>
