import 'vue-infinite-loading';
import pdfjsLib from 'pdfjs-dist';
import 'lodash';
import 'moment';
import 'mwc-pdf-viewer-component';
import 'mwc-research-components-common';
import 'mwc-research-document-reader';
import 'mwc-research-grid-card-view-component';
import 'mwc-research-list-component';
import 'mwc-research-grid-component';
import 'mwc-markets-qualitative-research';
window.pdfjsLib = pdfjsLib;
