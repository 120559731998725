<template>
    <QUALITATIVERESEARCHUI
        ref="UI"
        :showLoading="showLoading"
        :skin="skin"
        :errorCode="error.code"
        :settings="settings"
        :labels="mergeLabels(defaultConfig.labels)"
        :researchSetting="researchSetting"
        @settings-popover-hide="hideSettingsPopover"
    >
    </QUALITATIVERESEARCHUI>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import QUALITATIVERESEARCHUI from './qualitative-research-ui';
import labels from './assets/labels.json';

const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'mwc-markets-qualitative-research',
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    components: {
        QUALITATIVERESEARCHUI
    },
    props: {},
    data() {
        return {
            defaultConfig: {
                settings: {
                    autoHeight: false,
                    showHeader: false,
                    showSetting: false,
                    showBorder: false,
                    showLoading: false,
                    languageId: 'en-US',
                    initConfig: {
                        initialSettings: {
                            dateFormat: 'mmddyyyy_slash'
                        }
                    }
                },
                labels: labels,
                intlNamespace: 'mwc-markets-qualitative-research'
            },
            showLoading: false,
            researchSetting: null
        };
    },

    created() {
        this.namespace = utils.namespace('qualitative-research');
    },
    mounted() {
        if (!utils.isHidden(this.$el)) {
            if (!this.initialized) {
                this.showLoading = true;
                const { env } = this.mktdata.getOption();

                this.mktdata
                    .queryPermission({ fields: '2048' })
                    .then(permission => {
                        this.showLoading = false;
                        if (
                            permission &&
                            permission.token &&
                            permission.token.oauth2
                        ) {
                            this.researchSetting = {
                                environment: env,
                                authToken: permission.token.oauth2.token,
                                componentName: this.settings.researchType,
                                uid: permission.UID
                            };
                        }
                        this.initialized = true;
                    });
            }
        }
    },
    methods: {
        _setError(error) {
            this.error = error;
            this.showLoading = false;
        },
        hideSettingsPopover() {
            this.$emit('settings-popover-hide');
        }
    }
};
</script>
