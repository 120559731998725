var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      class: _vm.cls,
      attrs: {
        skin: _vm.watchedSettings.skin,
        errorMessage: _vm.errorMessage,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showSetting: _vm.watchedSettings.showSetting
      },
      on: { "setting-click": _vm.toggleSettings }
    },
    [
      !_vm.errorMessage
        ? _c(
            "div",
            {
              ref: "content",
              class: [_vm.namespace("container")],
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _vm.researchSetting
                ? _c("mwc-research-list-component", {
                    ref: "researchComponent",
                    attrs: {
                      id: _vm.researchComponentId,
                      "mwc-id": _vm.researchComponentId
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("markets-ui-settings", {
        ref: "qualitativeResearchUiSettings",
        attrs: {
          slot: "settings",
          skin: _vm.watchedSettings.skin,
          showBack: !!_vm.settingsPopoverNavSelected,
          "triggered-by": _vm.settingsPopoverTriggeredId,
          visible: _vm.settingsPopoverVisible
        },
        on: { hide: _vm.hideSettingsPopover, back: _vm.changeSettingsNav },
        slot: "settings"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }